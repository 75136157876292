.mapLink {
    object-fit: cover;
    height: 11vh;
    min-height: 60px;
    width: 13vw;
    min-width: 150px;
    border-radius: 10%;
    margin-bottom: 10%;
  }
  
  .visit_schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 35vh;
    text-align: center;
    background-color: #013a63;
    color: white;
    padding: 10px 10px;
    margin-bottom: 3%;
  }
  
  .visit_schedule > h1 {
    font-size: 3rem;
    font-weight: 400;
    font-family: Andada SC;
  }
  
  .visit_schedule > p {
    font-size: 1rem;
    font-weight: 300;
    padding: 25px 0;
  }
  
  .image {
    text-align: center;
    margin: auto auto;
    width: 100%;
    flex-basis: 50%;
    flex-grow: 1;
    color: white;
    position: relative;
  }
  
  .image_img {
    display: block;
    width: 100%;
    min-height: 25vmax;
    /* max-height: 10vmax; */
    object-fit: cover;
    object-position: center;
  }
  
  .image_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.25s;
  }

  .image_overlay >* {
      transform: translateY(20px);
      transition: transform 0.25s;
  }

  .image_overlay:hover > p {
    opacity: 1;
  }

  .image_overlay:hover > a {
    opacity: 1;
  }

  .image_overlay:hover >* {
      transform: translateY(0);
  }
  
  .image_overlay:hover {
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
  }
  
  .image_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    margin: 5vh auto;
  }
  
  .image_title {
    font-size: 3vmax;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif;
    align-items: center;
  }
  
  .image_description {
    font-size: 1.5vmax;
    margin-top: 0.25em;
    opacity: 0;
    width: 70%;
  }

  .image_button{
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: larger;
      background-color: #5B7581;
      color: white;
      border-radius: 10px;
      margin: 15px 0;
      height: 50px;
      width: 15em;
      opacity: 0;
  }
  
  @media only screen and (max-width: 720px) {
    .image_wrapper {
      display: flex;
      flex-direction: column;
    }

    .image_button{
        font-size: medium;
        width: 7em;
    }
  
    .visit_schedule > h1 {
      font-size: 2rem;
    }
  
    .visit_schedule > p {
      font-size: 0.7rem;
    }

    .visit_schedule > h1 {
        font-size: 3rem;
      }
      
      .visit_schedule > p {
        font-size: 1rem;
      }
  }
  