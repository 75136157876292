.footer{
    background-color: #5B7581;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer_logo{
    max-height: 20vh;
    padding: 20px 0px;
}

.footer_contactInfo{
    text-align: center;
    padding-bottom: 25px;
}

.footer_pageLinksWrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 10px;
    text-align: center;
}

.footerOption{
    color: white;
}

.icon{
    color: white;
    padding: 0px 10px;
}

.footerLink{
    text-decoration: none;
}

.footer_contactInfo{
    color: white;
    text-decoration: none;
}