.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
}

.contact_schedule{
    background-image: url('/src/Images/Map.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 10% 0;
    width: 86vw;
    height: 50vh;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
}

.schedule_text{
    width: fit-content;
    height: fit-content;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.schedule_text > p{
    font-size: 1.4rem;
    padding-top: 25px;
}

.schedule_text > h3{
    font-weight: 200;
    font-size: 2rem;
    padding: 25px 25px;
}

.socialBox{
    height: 10vmax;
    border-radius: 7%;
}

.contact_infoBox{
    width: 80vw;
    min-height: 31vh;
    border-radius: 30px;
    background-color: #52B69A;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 25px 0px;
}

@media screen and (max-width: 767px) {
    .schedule_text > p{
        font-size: 1rem;
    }
    .socialBox{
        width: 50vmin;
        height: 20vmax;
        min-height: 90px;
        min-width: 150px;
        align-self: center;
        margin-bottom: 5%;
    }
    .socialBox:nth-child(3){
        margin-bottom: -5%;
    }
}

@media screen and (max-width: 455px) {
    .schedule_text > p{
        font-size: 13px;
    }
    .schedule_text > h3{
        font-size: 1.5rem;
    }
}
