.gospel_pageTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    font-weight: 300;
    margin-top: 7%;
}

.gospel_linkboxWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 7% 0;;
}