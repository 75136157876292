.members{
  color: white;  
}
.ministry_image_Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.announcement_image{
    width: 100vmin;
}

.members_buttons{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.langButton{
    background-color: #5B7581;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.langButton:hover{
    cursor: pointer;
}

.meetings_textWrapper{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.members_links{
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.members_links > a{
    color: white;
    text-decoration: none;
}

.members_links > a:hover{
    color: black;
}
.meeting_subtitle{
    font-weight: 200;
    font-size: 1.5rem;
}

#hidden{
    display:none;
}

@media only screen and (max-width: 690px) {
    .members_links > a{
        padding: 10px 15px;
    }
    .meetings_textWrapper{
        flex-direction: column;
    }
}