.navbar{
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 20;
  }
  
  .nav_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    height: 5vmax;
  }
  
  .nav_logo{
    align-items: center;
    margin-right: auto;
    cursor: pointer;
  }

  .nav_logoImg{
    height: 20vh;
    min-height: 150px;
    max-height: 250px;
  }
  
  .nav_menu{
    display: flex;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-right: 30px;
    margin-right: 2rem;
    height: 100%;
    border-radius: 35px;
    background-color: black;
  }
  
  .nav_links{
    display: flex;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  
  .nav_item:after{
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav_item:hover::after{
    width: 100%;
    background: #5B7581;
  }
  
  .nav_item .active{
    border-bottom: 3px solid #5B7581;
    color: lightblue;
  }

  #hideIcon{
      display: none;
  }

  #displayIcon{
      color: white;
      display: flex;
  }

  .nav_icon{
      display: none;
  }

  .nav_icon>button{
      background-color: transparent;
      border-color: transparent;
  }

  @media only screen and (max-width: 960px){
    #menuClicked{
        background-color: #5B7581;
    }
    
    .nav_container{
        position: absolute;
        display: flex;
    }  

    .nav_menu{
          display: flex;
          flex-direction: column;
          width: 100vw;
          border-top: 1PX SOLID #fff;
          position: absolute;
          top: 110px;
          left: -120%;
          opacity: 0;
      }

      .nav_menu.active{
          border-radius: 0;
          height: 60vh;
          left: 0px;
          opacity: 1;
          transition: all 0.5s ease;
          z-index: 1;
          background-color: #5B7581;
      }

      .nav_item:hover::after{
        background: #013A63;
      }

      .nav_item{
          margin: auto 0;
          padding-right: 23%;
          height: 0px;
      }

      .nav_item .active{
        border-bottom: 3px solid #013A63;
        color: #013A63;
      }

      .nav_links{
          padding: -20px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          width: 100vw;
      }

      .nav_icon{
          display: block;
          position: absolute;
          top: 0%;
          right: 0;
          transform: translate(-100%, 60%);
          font-size: 1.0rem;
          cursor: pointer;
      }
  }