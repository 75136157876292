.home_hero{
    width: 100%;
    height: 100vh;
    min-height: 800px;
    background-image: url(/src/Images/homeHero.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.home_heroText{
    width: 80%;
    text-align: center;
}

.home_heroPar{
    width: 25%;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5% 5%;
}

.home_heroText > h1{
    font-size: 6vw;
    font-weight: 900;
    font-family: Fahkwang;
}

.home_heroText > p{
    padding-top: 10vh;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.home_welcome{
    width: 90%;
    margin: 2% auto;
    min-height: 35vmax;
    padding: 30px 0px;
    background-color: #5B7581;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home_welcome > h1{
    font-size: 6vw;
    font-family: 'Bebas Neue';
}

.home_welcome > p{
    text-align: center;
    font-family: 'Gabriela';
    font-size: 1.5rem;
    margin-bottom: 5%;
}

.home_socialBox{
    object-fit: cover;
    height: 15vh;
    width: 18vw;
    min-height: 110px;
    min-width: 250px;
    border-radius: 30px;
    padding: 10px 10px;
}

.home_testimony{
    width: 90%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    align-items: center;
    min-height: 20vmin;
    padding: 0 0;
    background-color: #5B7581;
}

.home_testimony >p{
    width: 60%;
    margin: 0 auto;;
    color: white;
    font-family: 'Playfair Display';
    font-weight: normal;
    font-size: 1.7vmax;
}

@media only screen and (max-width: 690px) {
    .home_heroPar{
        display: none;
    }
    .home_heroText > h1{
        font-size: 4rem;
    }
}